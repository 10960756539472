export default (to, from, savedPosition) => {
      console.log('scrollBehavior to', to);
      console.log('scrollBehavior from', from);
      console.log('scrollBehavior savedPosition', savedPosition);

      const scrollTarget = document.querySelector('.scrollArea');
      console.log(scrollTarget);
      console.log(scrollTarget.scrollTop);
      if (scrollTarget) {
        if (savedPosition !== null) {
          /*
          뒤로가기 등의 popState성 이동일 경우
          scrollArea 내부의 스크롤 포지션 유지를 위해
          별도의 저장소에 담아뒀던 scrollPos를 to에 넣어줌
           TODO: 저장소에서 가져오는 로직으로 변경 할 것
          from에 넣어 뒀다가 그냥 꺼내서 쓰면?
          */

          to.meta.scrollPos = { x: 0, y: 0 };
        } else {
          /*
          TODO: popState성 이동이 아닐경우 현재 페이지의 scrollArea 스크롤포지션을
          별도의 저장소에 저장한다.
          */
          /*
          if (from.name === 'mb-mbb-SCR_MBB_0097A') {
            to.params.afterLogin = true
          }
          */
          to.meta.scrollPos = { x: 0, y: 0 };
        }
      }
      return { x: 0, y: 0 };
    }
